.divider {
  width: 114%;
  margin-left: -8% !important;
  margin-top: 20px !important;
}
.bankSectionDivider {
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px 0 20px;
  padding-top: 0px;
}

.bankInfoTitle {
  display: flex;
  align-items: center;
}

.bankIconContainer {
  width: 32px;
  height: 32px;
  padding: 8px;
  margin-right: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ebe9fe;
}

.input {
  position: relative;
  cursor: pointer;
}
.input:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  border: 1px solid #667085;
  border-radius: 4px;
  background-color: white;
}

.input:disabled:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  background-color: #f2f4f7;
}

.input:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  border: 1px solid #7f56d9;
  border-radius: 4px;
  background-color: #f2f3ff;
}

.input:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: 1px solid #7f56d9;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 4.8px;
}
