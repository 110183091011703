.complete-provider-before-pay-modal-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
}

.complete-provider-before-pay-modal-description {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
}
