body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.layout-container {
  width: 100%;
  margin: 0 auto;
  background-color: #f6f7f9;
}

.tx-detail-layout-container {
  max-width: 1200px;
  margin: 0 auto;
}

.tx-status-pill {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.customer-layout-container {
  width: 100%;
  margin: 0 auto;
}

#menu-documentType {
  z-index: 99999;
}

#menu-accountHolderDocumentType {
  z-index: 99999;
}

#menu-accountType {
  z-index: 99999;
}

.modal-header-payana {
  padding: 16px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}

.limit-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.document-column {
  min-width: 100px;
  max-width: 100px;
}
.invoice_number-column {
  min-width: 140px;
  max-width: 140px;
}
.status-column {
  min-width: 150px;
  max-width: 150px;
}
.invoice_dates-column {
  min-width: 135px;
  max-width: 135px;
}
.provider-name-column {
  width: 27%;
}
.amount-header {
  text-align: right !important;
  padding-right: 40px !important;
}
.container-see-details {
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.container-see-details:hover .text {
  color: #3c1b8f;
}
.container-see-details:hover .chevron {
  stroke: #3c1b8f;
}
.amount_total-column {
  width: 120px;
}
.pill-header {
  padding: 4px 0px;
}
.sorting-header {
  cursor: pointer;
  padding: 4px;
  transition: background-color 0.4s ease;
  display: flex;
  justify-content: space-between;
  margin-left: -5px;
}
.sorting-arrow {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.table:hover .sorting-arrow {
  opacity: 1;
}
.table:hover .sorting-header {
  background-color: #eaecf0;
  border-radius: 4px;
}
.sorting-amount {
  width: 71px;
  padding-right: 6px;
}
.transaction-details-status-pill {
  margin: 0 !important;
  margin-bottom: 6px !important;
}

body {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Definición de la animación */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Clase para aplicar la animación de Fade-In */
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.incompleted-provider-indicator {
  color: #b54708;
  text-decoration: none;
}
.incompleted-provider-indicator:hover {
  color: #943e05;
}

.causation {
  border-bottom: 1px solid #eaecf0;
}
.causation:hover {
  background-color: #eaecf0; /* Color gris para filas impares */
}
.multiple-cell-select.MuiDataGrid-cell {
  border: 1px solid #bdb4fe !important;
  border-radius: 8px !important;
  margin: 1px !important;
}
.tax-iva-container.MuiDataGrid-cell {
  padding: 0px !important;
}
.cell-select.MuiDataGrid-cell {
  border: 1px solid #bdb4fe !important;
}
.super-app-theme--cell.MuiDataGrid-cell {
  padding: 0px !important;
}
.autocomplete-table-container {
  padding: 10px;
  box-shadow: 0 0 10px gray;
  background: #fff;
  border-radius: 0.5rem;
  min-width: 500px;
}
.autocomplete-container {
  padding: 10px;
  box-shadow: 0 0 10px gray;
  background: #fff;
  border-radius: 0.5rem;
  min-width: 300px;
}
.button-without-default-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@keyframes fadeInWithTranslation {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.fade-in-with-translation {
  animation: fadeInWithTranslation 0.5s ease-in forwards;
}

@keyframes fadeOutWithTranslation {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}
.fade-out-with-translation {
  animation: fadeOutWithTranslation 0.5s ease-in forwards;
}

.debts-search-container-loading {
  opacity: 0;
  height: 0px;
}
@keyframes delayedFadeInWithTranslation {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    height: auto;
  }
}
.delayed-fade-in-with-translation {
  animation: delayedFadeInWithTranslation 0.5s ease-in forwards;
  animation-delay: 0.4s;
}

.circle {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5925dc;
  font-size: 16px;
  font-weight: regular;
  color: #5925dc;
}
