.dialog-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.failed-message-items-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 600;
  min-width: 550px;
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #eaecf0 #f3f3f3;
  padding-right: 12px;
  padding-bottom: 5vh;
}
#scroll-dialog-title {
  padding: 24px 24px 12px 24px;
}
.dialog-container {
  position: relative;
}
.scroll-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
  pointer-events: none;
}
.dialog-header-title {
  color: #475467;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px;
}
.dialog-header-subtitle {
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}
.list-item-container {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 24px;
}
.list-item {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 278px;
}
.list-item-header {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
}
.help-text {
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
