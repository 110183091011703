.dian-modal-close-button {
  text-align: right;
  margin-right: -10px;
  margin-top: -10px;
}
.dian-modal-header-icon {
  text-align: right;
}
.dian-modal-text {
  color: #475467;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
}
.dian-modal-step-1-text {
  color: #475467;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  max-width: 330px;
  margin: 0 auto;
}
.modal-text {
  color: #475467;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  max-width: 330px;
  margin: 0 auto;
}
.dian-modal-title {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.my-container {
  max-height: 90px;
  overflow: hidden;
  transition: all 0.25s ease;
}
@keyframes expandStepOne {
  to {
    max-height: 565px;
  }
}
.step-zero {
  min-height: 285px;
}
.step-one {
  min-height: 275px;
  animation: expandStepOne 0.25s ease forwards;
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: #667085 #ededed !important;
}
.step-two {
  min-height: 550px;
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: #667085 #ededed !important;
}
.step-two-with-startdate {
  min-height: 550px;
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: #667085 #ededed !important;
}
.step-three {
  min-height: 105px;
}
.step-four {
  min-height: 208px;
}
.step-six {
  min-height: 187px;
}
.step-seven {
  min-height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
