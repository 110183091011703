.icon-container {
  text-align: center;
  margin-bottom: 8px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
  color: #101828;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  line-height: 24px;
  color: #344054;
  margin-bottom: 16px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  color: #344054;
  margin-bottom: 32px;
}

.button-container {
  margin-top: 2;
  width: 100%;
}
