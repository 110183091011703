.debt-list-item-company-logo {
  border-radius: 50%;
  border: 2px solid #eaecf0;
  padding: 5px;
  height: fit-content;
  min-width: 48px;
  min-height: 48px;
}

.debt-list-item-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  padding: 16px;
  margin-bottom: 16px;
}

.debt-list-item-inner-container {
  display: flex;
  gap: 8px;
}

.debt-list-item-inner-list {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.hint {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  color: #667085;
  align-self: flex-start;
}