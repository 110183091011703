.icon-container {
  text-align: center;
  margin-bottom: 8px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 28px;
  color: #101828;
  margin-top: 8px;
}

.multi-title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 28px;
  color: #101828;
  margin-top: 8px;
  margin-bottom: -12px;
}

.success-title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 28px;
  color: #101828;
  margin-top: 8px;
  align-self: flex-start;
}

.new-title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 28px;
  color: #101828;
  margin-top: 8px;
  align-self: flex-start;
}

.description {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: left;
  line-height: 24px;
  color: #667085 !important;
}

.new-description {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left;
  line-height: 24px;
  color: #667085 !important;
  margin-right: 48px;
}

.manager-description {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left;
  line-height: 24px;
  color: #667085 !important;
  margin-right: 48px;
  align-self: flex-start;
}

.centered-description {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
  color: #667085;
}

.button-container {
  margin-top: 2;
}

.user-container {
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  background: #f2f4f7;
  padding: 16px;
  height: 85px;
  margin-top: 16px;
  cursor: pointer;
  width: 100%;
}

.initials {
  font-weight: 600;
  font-size: 18px;
  color: #344054;
}

.name-field {
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
  align-self: flex-start;
  align-items: flex-start;
}

.document-field {
  font-size: 14px;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;
  gap: 12px;
  width: 100%;
}

.label {
  color: #1d2939 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: -6px;
}

.input {
  width: 100%;
}

.emptyInput {
  width: 100%;
  color: #a2a2a2;
}

.hint {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  color: #667085;
  align-self: flex-start;
}

.terms {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 20px;
  color: #475467;
  align-self: center;
}
