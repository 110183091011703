.icon-container {
  text-align: center;
  margin-bottom: 8px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 28px;
  color: #101828;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
  color: #667085;
}

.button-container {
  margin-top: 2;
}