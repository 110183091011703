.tabsContainer {
  width: 100%;
  height: 100%;
  background: #e9e9ed;
  padding: 5px;
  border-radius: 5px;
  height: 36px;
  margin-top: 24px;
}

.tabsContainerMobile {
  width: 95%;
  height: 100%;
  background: #f2f4f7;
  padding: 5px;
  border-radius: 5px;
  height: 36px;
  margin-top: 24px;
  margin-left: 2.5%;
}

.tabBase {
  width: 50%;
  border: none;
  background: transparent;
  border-radius: 5px;
}

.labelBase {
  font-size: 14px;
  font-weight: 550;
  margin-left: 5px;
  cursor: pointer;
  color: #667085;
}

.tabSelected {
  background: white;
}

.labelSelected {
  color: #6938ef;
}
