.container {
  background-color: #eff2f6;
  border-radius: 8px;
  border-color: #eff2f6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardTitle {
  color: #667085;
  font-weight: 400;
  font-size: 14px;
}

.cardText {
  color: #344054;
  font-weight: 500;
  font-size: 14px;
}
