.container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.title {
  color: #1d2939;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 8px;
}

.button-container {
  margin-top: 32px;
  display: flex;
  gap: 12px;
}