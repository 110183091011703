.input {
  position: relative;
  cursor: pointer;
}
.input:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  border: 1px solid #667085;
  border-radius: 4px;
  background-color: white;
}

.input:disabled:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  background-color: #f2f4f7;
}

.input:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  border: 1px solid #7f56d9;
  border-radius: 4px;
  background-color: #f2f3ff;
}

.input:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: 1px solid #7f56d9;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 4.8px;
}

.tabsContainer {
  width: 100%;
  height: 100%;
  background: #f2f4f7;
  padding: 5px;
  border-radius: 5px;
  height: 44px;
}

.accountingTabsContainer {
  height: 52px;
  border-bottom: 1px solid #eaecf0;
  position: sticky;
  top: 28px;
  background-color: #ffffff;
  padding-top: 8px;
  z-index: 2;
}

.tabsContainerMobile {
  width: 95%;
  height: 100%;
  background: #f2f4f7;
  padding: 5px;
  border-radius: 5px;
  margin-top: 24px;
  margin-left: 2.5%;
}

.tabBase {
  width: 50%;
  border: none;
  background: transparent;
  border-radius: 6px;
  height: 36px;
  margin-top: -1px;
}

.labelBase {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #667085;
}

.tabSelected {
  background: white;
}

.labelSelected {
  color: #344054;
  font-weight: 500;
  font-size: 14px;
}

.accountingTabBase {
  width: 50%;
  border: none;
  background: transparent;
  height: 44px;
}

.accountingLabelBase {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #667085;
}

.accountingTabSelected {
  background: #f4f3ff;
  border-bottom: 2px solid #5925dc;
}

.accountingLabelSelected {
  color: #5925dc;
  font-weight: 500;
  font-size: 14px;
}
