.customer-incompleted-message-container {
  background: #fffcf5;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #fec84b;
}
.customer-incompleted-message-title {
  color: #b54708;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  font-size: 14px;
}
.customer-incompleted-message-description {
  color: #dc6803;
  font-weight: 400;
  margin-bottom: 8px;
  font-size: 14px;
}
.customer-incompleted-message-link {
  color: #b54708;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}
.customer-incompleted-message-link > span {
  padding-right: 8px;
}
