.form {
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px !important;
}

.inputLabel {
  font-size: 14px;
  font-weight: "500";
  cursor: "pointer";
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  padding: 35px 10px 0 20px;
  margin-bottom: 16px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.divider {
  width: 120%;
  margin-left: -12%;
}
