.dian-modal-close-button {
  text-align: right;
  margin-right: -10px;
  margin-top: -10px;
}
.dian-modal-header-icon {
  text-align: right;
}
.attention-modal-header-icon {
  text-align: center;
}
.siigo-modal-text {
  color: #7a2e0e;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  background-color: #fef0c7;
  padding: 8px 13px 8px 13px;
  border-radius: 8px;
}
.dian-modal-step-1-text {
  color: #475467;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  max-width: 330px;
  margin: 0 auto;
}
.dian-modal-title {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.my-container {
  max-height: 90px;
  overflow: hidden;
  transition: all 0.25s ease;
}
@keyframes expandStepOne {
  to {
    max-height: 535px;
  }
}
.siigo-step-one {
  min-height: 235px;
  animation: expandStepOne 0.25s ease forwards;
}
.siigo-step-two {
  min-height: 408px;
}
.siigo-step-three {
  min-height: 105px;
}
.siigo-step-four {
  min-height: 695px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.siigo-step-six {
  min-height: 187px;
}

.siigo-step-seven {
  min-height: 275px;
}

.siigo-step-eight {
  min-height: 210px;
}

.icon-container {
  text-align: center;
  margin-bottom: 8px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 28px;
  color: #101828;
  margin-bottom: 8px;
  margin-top: 6px;
}

.description {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
  color: #667085;
}

.button-container {
  margin-top: 2;
}
