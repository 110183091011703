.massive-create-debts-list-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding-bottom: 100px;
}
.massive-create-debts-list-title {
  color: #1d2939;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 32px;
}
