.container {
  width: 35vw;
  height: 90vh;
  padding: 30px;
  min-width: 470px;
}

.title {
  text-align: center;
  font-weight: 500;
  color: #101828;
  font-size: 24px;
}

.textFieldContainer {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.containerNotes {
  height: 60%;
  overflow-y: scroll;
}
