* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.navDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customerNavDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.nav li {
  align-self: center;
  width: 100%;
}

.nav {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  width: 100%;
}

.customer-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  height: 64px;
  border-bottom: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  width: 100%;
  margin-top: -64px;
}

.nav ul {
  list-style: none;
  display: flex;
  width: 100%;
}

.nav li a {
  text-decoration: none;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: #344054;
  justify-self: center;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav li.active a {
  text-decoration: none;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: #ffffff;
  background-color: #6938ef;
  border-radius: 6px;
  width: -webkit-fill-available;
}

.submenu {
  background-color: #fcfcfd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  height: 52px;
  border-bottom: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.submenuDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fcfcfd;
}

.submenu li {
  align-self: center;
  margin-left: 8px;
}

.submenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.submenu a {
  text-decoration: none;
  margin-left: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  justify-self: center;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.submenu li.active a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6927da;
  border-radius: 6px;
}

.submenu li:hover a {
  color: #6927da;
  font-weight: 600;
}

.table {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  border-spacing: 0;
  border-collapse: separate;
}

.tablehead {
  background-color: #f9fafb;
}

.tablehead th {
  background-color: #f9fafb;
  text-align: left;
  height: 44px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #101828;
}

.tablehead th:not(:first-child) {
  padding-bottom: 10px;
}

.tablehead tr {
  border-style: none;
  border-color: transparent;
}

.tablehead th:first-child {
  border-top-left-radius: 8px;
  padding-left: 24px;
}

.tablehead th:last-child {
  border-top-right-radius: 8px;
}

.tablebody tr {
  vertical-align: middle;
}

.tablebody tr:last-child td {
  border: none;
}

.tablebody td {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #475467;
  border-bottom: 1px solid #eaecf0;
  padding: 0rem 0.5rem;
}

.tablebody td:nth-child(1) {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #101828;
  text-transform: uppercase;
  padding-left: 24px;
}

.paginationNav {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.paginationNavMobile {
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  width: 100%;
}

.modal {
  padding: 0px;
}

.modalTitle {
  color: #101828;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.tabContext {
  color: #101828;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Right*/
.modal.right.show .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 16px;
  border: none;
  width: fit-content;
}

.modal-dialog-centered {
  min-width: fit-content;
  max-width: fit-content;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

#sidebarModal {
  width: 35vw;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
}

.dropzone {
  text-align: center;
  padding: 30px;
  border: 1px dashed #eaecf0;
  background-color: #f9fafb;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 8px;
}

.bank-extract-dropzone {
  text-align: center;
  padding: 16px 24px 16px 24px;
  border: 2px solid #bdb4fe;
  background-color: FAFAFF;
  cursor: pointer;
  border-radius: 8px;
  height: fit-content;
  width: 100%;
}

.mini-dropzone {
  text-align: center;
  border: 1px dashed #eaecf0;
  background-color: #f9fafb;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}

.page-link {
  color: #5925dc;
}

.page-link:focus {
  box-shadow: none;
}

.dropdown-toggle:after {
  display: none !important;
}

@media (max-width: 481px) {
  .submenu,
  .sync-cs,
  .invoices-data-filters,
  .navDesktop {
    display: none;
  }

  .navMobile {
    display: block;
  }

  .layout-container {
    width: 100%;
    margin: 0 auto;
  }

  .customer-layout-container {
    width: 100%;
    margin: 0 auto;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
  right: 0;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  height: auto !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.dropdown {
  float: left;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: white;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content a:visited {
  background-color: #ddd;
}

.dropdown-content:active {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-device-width: 481px) {
  .bm-item-list {
    color: #b8b7ad;
    padding: 0em !important;
    height: auto !important;
    margin-top: 16px;
  }

  .nav li {
    margin-left: 0px !important;
  }

  .nav a {
    text-decoration: none;
    margin-left: 4px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    justify-self: center;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 12px 0px !important;
  }

  .nav li.active a {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6927da;
    background-color: #f5f3ff;
    padding: 8px 8px !important;
    border-radius: 6px;
  }
}

.MuiInputLabel-root {
  font-size: 14px !important;
}

.lbl-checkbox-header {
  display: inline-block;
  padding: 6px 12px 0px 12px;
  cursor: pointer;
}

.lbl-checkbox {
  display: inline-block;
  padding: 12px 12px 12px 12px;
  cursor: pointer;
}

.MuiMenu-list {
  padding-top: 0px !important;
}
.tagsList .optionListContainer {
  display: block;
  border: none !important;
}
.optionListContainer {
  border: none !important;
}
.optionContainer {
  border: none !important;
}

button.payana-primary-button {
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.1s;
}
button.payana-primary-button-disabled {
  padding: 10px 20px;
  border-radius: 4px;
}

button.payana-primary-button:active {
  transform: translateY(2px);
  background-color: #3f2878 !important;
  transform: translateY(2px);
}

button.payana-third-primary-button {
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.1s;
}
button.payana-third-primary-button-disabled {
  padding: 10px 20px;
  border-radius: 4px;
}

button.payana-third-primary-button:active {
  transform: translateY(2px);
  background-color: rgb(200, 201, 220) !important;
  transform: translateY(2px);
}

.checkbox {
  position: relative;
  cursor: pointer;
}

.checkbox:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  border: 1px solid #7f56d9;
  border-radius: 4px;
  background-color: #f2f3ff;
}

.checkbox:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: 1px solid #7f56d9;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 4.8px;
}

.receiverComissionInput .MuiOutlinedInput-root {
  padding-right: 0px !important;
}

.collectionForm.css-19midj6 {
  padding: 0px !important;
}

.tag-pill-default {
  padding: 2px 8px;
  margin: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  text-align: center;
  color: #344054;
  background-color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #d0d5dd;
}

.receiverAmount input {
  color: #101828;
}

.receiverAmountExceeded input {
  color: #f04438;
}

@keyframes moveAndFade {
  0% {
    transform: translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animated-element {
  animation: moveAndFade 1s ease-out;
}

.company-switch-modal {
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: #d0d5dd transparent !important;
}

.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1055;
  border: 0px;
}

.modal-header--sticky-2 {
  position: sticky;
  top: 32px;
  background-color: #ffffff;
  z-index: 1055;
  border: 0px;
}

.outer {
  border-radius: 24px;
  overflow: hidden;
}

.inner {
  overflow-y: auto;
}

.highlight {
  box-sizing: inherit !important;
  border: 4px solid #7a5af8;
  border-radius: 8px;
  box-shadow: rgba(33, 32, 33, 0.8) 0px 0px 1px 0px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

.higlight-tooltip {
  background-color: #fcfaff;
  border: 1px solid #d6bbfb;
  border-radius: 8px;
  margin-left: 12px;
  min-width: 375px;
  max-height: 168px;
}

.higlight-tooltip .introjs-tooltipbuttons {
  border-top: 0px;
}

.higlight-tooltip .introjs-skipbutton {
  color: #9e77ed;
}

.higlight-tooltip .introjs-donebutton {
  background-color: #6938ef;
  border-radius: 8px;
  height: 32px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  width: 152px;
  border: none;
  text-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #4f29b6;
  }
}

.tx-higlight-tooltip {
  background-color: #fcfaff;
  border: 1px solid #d6bbfb;
  border-radius: 8px;
  margin-left: 12px;
  min-width: 450px;
}

.tx-higlight-tooltip .introjs-tooltipbuttons {
  border-top: 0px;
}

.tx-higlight-tooltip .introjs-skipbutton {
  color: #9e77ed;
}

.tx-higlight-tooltip .introjs-nextbutton {
  background-color: #6938ef;
  border-radius: 8px;
  height: 24px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 152px;
  border: none;
  text-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #4f29b6;
  }
}

.introjs-bullets ul li a.active {
  background: #4f29b6;
}

.introjs-bullets ul li a {
  background: #4f29b6;
  &:hover {
    cursor: pointer;
    background: #4f29b6;
  }
}

.tx-higlight-tooltip .introjs-donebutton {
  background-color: #6938ef;
  border-radius: 8px;
  height: 24px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 152px;
  border: none;
  text-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #4f29b6;
  }
}

.introjs-hidden {
  display: none !important;
}

.tx-higlight-tooltip .introjs-prevbutton {
  background-color: #ffffff;
  border-radius: 8px;
  height: 24px;
  color: #6938ef;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 152px;
  border: none;
  text-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6938ef;
}

.introjs-button {
  text-shadow: none;
}

.introjs-tooltip-header {
  display: none;
}

.introjs-tooltiptext {
  padding: 12px;
  margin-bottom: -24px;
}

.multiplePayments {
  display: flex;
  flex-direction: column;
}

.button-without-default-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}
