.icon-container {
  text-align: center;
  margin-bottom: 8px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 28px;
  color: #101828;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
  color: #667085;
}

.available {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
  color: #667085;
  margin-top: 4px;
}

.button-container {
  margin-top: 20px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
