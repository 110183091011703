.tabsContainer {
  max-width: 880px;
  width: 100%;
  height: 100%;
  background: #f2f4f7;
  padding: 5px;
  border-radius: 5px;
  height: 36px;
  align-self: center;
}

.tabBase {
  width: 50%;
  border: none;
  background: transparent;
  border-radius: 5px;
}

.labelBase {
  font-size: 14px;
  font-weight: 550;
  margin-left: 5px;
  cursor: pointer;
  color: #667085;
}

.tabSelected {
  background: white;
}

.labelSelected {
  color: #6938ef;
}
