.disabled-receivers-container {
  border-radius: 8px;
  background-color: #eaecf0;
  margin-top: 24px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.disabled-receivers-alert-container {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 16px;
  color: #475467;
  background-color: #fcfcfd;
  font-size: 12px;
  display: flex;
  gap: 12px;
}
.disabled-receivers-switch-container {
  border-radius: 8px;
  padding: 6px;
  color: #344054;
  background-color: #fcfcfd;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.disabled-receivers-alert-configuration-link {
  margin-top: 8px;
  font-size: 14px;
}
.disabled-receivers-alert-configuration-link-a {
  color: #344054;
  text-decoration: none;
}
.disabled-receivers-alert-receivers {
  color: #344054;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}
