input[type="checkbox"].custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 17px;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

input[type="checkbox"].custom-checkbox:checked {
  background-color: #ffffff;
  border: 1px solid #7f56d9;
}

input[type="checkbox"].custom-checkbox:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: #7f56d9;
  border-radius: 50%;
}

input[type="checkbox"].custom-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: #7f56d9;
  border-radius: 50%;
}
