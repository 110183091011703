.source-of-funds-option {
  color: #667085;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
  justify-content: center;
  transition: box-shadow 0.25s ease-in-out;
  box-shadow: 0px 3px 6px 0px rgba(16, 24, 40, 0.12),
    0px 3px 8px 0px rgba(16, 24, 40, 0.1);
}
.source-of-funds-option:hover {
  box-shadow: 0px 4px 8px 0px rgba(16, 24, 40, 0.2),
    0px 4px 10px 0px rgba(16, 24, 40, 0.12);
}
.source-of-funds-option.actived {
  color: #5925dc;
  background: #fff;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.bank-account-form {
  min-height: 280px;
}
.card-reference-form {
  min-height: 280px;
}
.register-new-form {
  min-height: 160px;
}
.update-source-of-funds-form {
  min-height: 40px;
}
.update-source-of-funds-form {
  min-height: 40px;
  animation: fadeIn 1s ease;
}
.my-container {
  max-height: 90px;
  overflow: hidden;
  transition: all 0.25s ease;
}
.my-container.card-reference {
  max-height: 190px;
}
.my-container.bank-account {
  max-height: 390px;
}
.my-container.register-new {
  max-height: 450px;
}
