.debts-search-container {
  background-color: #f9fafb;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
}
.debts-search-title {
  color: #1d2939;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
.debts-search-subtitle {
  color: #475467;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
}
.debts-search-form-container {
  margin-top: 16px;
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
  align-items: flex-end;
}
.debts-search-form-label {
  color: #1d2939;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 481px) {
  .debts-search-form-container {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
}